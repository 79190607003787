import React from 'react'
import styles from 'src/styles/banner.module.css'

const Banner = ({title, description, children}) => {
    return (
        <div className={styles.banner}>
            <h1>{title}</h1>
            <p>{description}</p>
            {children}
        </div>
    )
}

export default Banner
