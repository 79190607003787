import React from 'react'
import styles from 'src/styles/about.module.css'
import Title from 'src/components/StyledTitle'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const getAbout = graphql`
    query aboutImage {
        aboutImage:file (relativePath: {eq:"defaultAbout.jpg"}){
        childImageSharp {
            fluid (maxWidth:600){
                ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const About = () => {
    const { aboutImage } = useStaticQuery(getAbout)
    return (
        <section className={styles.about}>
            <Title title="about" subtitle="me"/>
            <div className={styles.aboutCenter}>
                <article className={styles.aboutImg}>
                    <div className={styles.imgContainer}>
                        <Img fluid={aboutImage.childImageSharp.fluid} alt="about image" />
                    </div>
                </article>
                <article className={styles.aboutInfo}>
                    <h4>Hi, I'm Sherif</h4>
                    <p>And this is my personal website and blog.<br />
                        I'm a UCSC graduate, Bay Area Software Engineer, Technology Enthusiast, and a Hobbyist Maker.</p>
                </article>
            </div>
        </section>
    )
}

export default About