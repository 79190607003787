import React from 'react'
import Image from 'gatsby-image'
import styles from 'src/styles/post.module.css'
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Post = ({ post }) => {  
    const { fields, frontmatter } = post
    const slug = fields.slug
    const { description, title, mainImage, date } = frontmatter
    
    return (
        <article className={styles.blog}>
            <div className={styles.imgContainer}>
                <AniLink fade to={`/blog/${slug}`} >
                    <Image fluid={mainImage.childImageSharp.fluid} className={styles.img} alt="latest post" />
                </AniLink>
                <h6 className={styles.date}>{date}</h6>
            </div>
            <div className={styles.footer}>
                <h3>{title}</h3>
                <h6>{description}</h6>
            </div>
        </article>
    )
}

export default Post