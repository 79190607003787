import React, { useState } from 'react'
import Project from 'src/components/Projects/Project'
import Post from 'src/components/Blog/Post'
import { useStaticQuery, graphql } from 'gatsby'
import Title from 'src/components/StyledTitle'
import styles from 'src/styles/latest.module.css'
import Carousel from 'nuka-carousel';


const Latest = () => {
    const response  = useStaticQuery(getLatest)
    const latestProjects = response.latestProject.edges
    const latestPosts = response.latestPost.edges
    const [ isShowingProject, setisShowingProject ] = useState(true)
    
    return (
        <section className={styles.projects}>
            <Title title="latest" subtitle={isShowingProject? "project": "post"} />
            <div className={styles.center}>
            <Carousel className={styles.carouselContainer} afterSlide={isShowingProject => setisShowingProject(!isShowingProject)}>
              {latestProjects.map(({node}) => {
                return <Project key={node.id} project={node} />
              })}

              {latestPosts.map(({node}) => {
                return <Post key={node.id} post={node} />
              })}
            </Carousel>
            </div>
        </section>
    )
}

const getLatest = graphql`
query {
  latestProject:allMarkdownRemark(filter: {frontmatter: {type: {eq:"project"} featured:{eq:true}}}) {
    edges {
      node {
        html
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          tags
          github
          mainImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }

  latestPost:allMarkdownRemark(filter: {frontmatter: {type: {eq:"blog"} latest:{eq:true}}}) {
    edges {
      node {
        html
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          date (formatString: "MMMM DD, YYYY")
          mainImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}
`

export default Latest