import React from "react"
import Banner from 'src/components/Banner'
import Layout from 'src/components/layout'
import Hero from 'src/components/StyledHero'
import personalInfo from 'src/constants/personal-info'
import About from 'src/components/About'
import { graphql } from 'gatsby'
import Latest from 'src/components/Latest'
import { Element } from 'react-scroll'
import SEO from "src/components/seo"

export default ({ data }) => {
    return (
        <Layout>
            <SEO title="Home" />
            <Hero home={true} img={data.homeImage.childImageSharp.fluid}>
                <Banner title={personalInfo.name} description={personalInfo.description}/>
            </Hero>
            <Element name="latest"><Latest /></Element>
            <Element name="about"><About /></Element>
        </Layout>
    )
}

export const query = graphql`
    query homeImage {
        homeImage:file (relativePath: {eq:"defaultBcg.jpg"}){
        childImageSharp {
            fluid (quality:90, maxWidth:4160){
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        }
    }
`